@font-face {
  font-family: 'GmarketSans-B';
  src: url('static/styles/fonts/GmarketSans/GmarketSansTTFBold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'GmarketSans-M';
  src: url('static/styles/fonts/GmarketSans/GmarketSansTTFMedium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'SpoqaHanSans';
  font-weight: 400;
  font-display: fallback;
  src: local('SpoqaHanSansNeo-Regular'),
    url('static/styles/fonts/SpoqaHanSans/SpoqaHanSansNeo-Regular.woff2')
      format('woff2'),
    url('static/styles/fonts/SpoqaHanSans/SpoqaHanSansNeo-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'SpoqaHanSans';
  font-weight: 500;
  font-display: fallback;
  src: local('SpoqaHanSansNeo-Medium'),
    url('static/styles/fonts/SpoqaHanSans/SpoqaHanSansNeo-Medium.woff2')
      format('woff2'),
    url('static/styles/fonts/SpoqaHanSans/SpoqaHanSansNeo-Medium.woff')
      format('woff');
}

@font-face {
  font-family: 'SpoqaHanSans';
  font-weight: 700;
  font-display: fallback;
  src: local('SpoqaHanSansNeo-Bold'),
    url('static/styles/fonts/SpoqaHanSans/SpoqaHanSansNeo-Bold.woff2')
      format('woff2'),
    url('static/styles/fonts/SpoqaHanSans/SpoqaHanSansNeo-Bold.woff')
      format('woff');
}
